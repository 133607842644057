import { inputOutputTypes } from "./enums";

export default function InputParameters({ttpData, setTtpData, setError}) {
    const inputParameters = ttpData.input_parameters || null;

    return (
        <div className="w-full">
            <h1 className="block text-gray-700 text-sm mb-4 font-bold mb-2">Input Parameters</h1>
            <div className="flex flex-col space-y-4">
                <div className="flex mb-4">
                    <button className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded" onClick={() => {
                        const newInputParameters = inputParameters ? [...inputParameters] : [];
                        newInputParameters.push({
                            name: "",
                            display_name: "",
                            description: "",
                            type: "",
                            required: false,
                            autofill_on_engine: false,
                            autofill_on_dashboard: false
                        });
                        setTtpData({...ttpData, input_parameters: newInputParameters});
                    }}>Add Input Parameter</button>
                </div>
                {
                    inputParameters && inputParameters.map((inputParameter, index) => {
                        return (
                            <div key={index} className="flex flex-col border border-gray-300 p-4 rounded">
                                <div className="flex mb-4">
                                    <label className="w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor={`input_parameter_${index}_name`}>Name</label>
                                    <input className={"w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" +
                                        (inputParameter.name ? "" : " border-red-500") }
                                        id={`input_parameter_${index}_name`} type="text" placeholder="Name"
                                        value={inputParameter.name || ""}
                                        onChange={(e) => {
                                            const newInputParameters = [...inputParameters];
                                            newInputParameters[index].name = e.target.value;
                                            setTtpData({...ttpData, input_parameters: newInputParameters});
                                        }} />
                                </div>
                                <div className="flex mb-4">
                                    <label className="w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor={`input_parameter_${index}_display_name`}>Display Name</label>
                                    <input className={"w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" +
                                        (inputParameter.display_name ? "" : " border-red-500") }
                                        id={`input_parameter_${index}_display_name`} type="text" placeholder="Display Name"
                                        value={inputParameter.display_name || ""}
                                        onChange={(e) => {
                                            const newInputParameters = [...inputParameters];
                                            newInputParameters[index].display_name = e.target.value;
                                            setTtpData({...ttpData, input_parameters: newInputParameters});
                                        }} />
                                </div>
                                <div className="flex mb-4">
                                    <label className="w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor={`input_parameter_${index}_description`}>Description</label>
                                    <textarea className={"w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" +
                                        (inputParameter.description ? "" : " border-red-500") }
                                        id={`input_parameter_${index}_description`} placeholder="Description"
                                        value={inputParameter.description || ""}
                                        onChange={(e) => {
                                            const newInputParameters = [...inputParameters];
                                            newInputParameters[index].description = e.target.value;
                                            setTtpData({...ttpData, input_parameters: newInputParameters});
                                        }} />
                                </div>
                                <div className="flex mb-4">
                                    <label className="w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor={`input_parameter_${index}_type`}>Type</label>
                                    <select className={"w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white" +
                                        (inputParameter.type ? "" : " border-red-500") }
                                        id={`input_parameter_${index}_type`} placeholder="Type"
                                        value={inputParameter.type || ""}
                                        onChange={(e) => {
                                            const newInputParameters = [...inputParameters];
                                            newInputParameters[index].type = e.target.value;
                                            setTtpData({...ttpData, input_parameters: newInputParameters});
                                        }}>
                                        <option value="">Select a type</option>
                                        {
                                            inputOutputTypes.map((type, index) => {
                                                return <option key={index} value={type}>{type}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="flex mb-4">
                                    <label className="w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor={`input_parameter_${index}_required`}>Required</label>
                                    <select className={"w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white" +
                                        (inputParameter.required !== undefined ? "" : " border-red-500") }
                                        id={`input_parameter_${index}_required`} placeholder="Required"
                                        value={inputParameter.required !== undefined ? inputParameter.required : ""}
                                        onChange={(e) => {
                                            const newInputParameters = [...inputParameters];
                                            newInputParameters[index].required = e.target.value === "true";
                                            setTtpData({...ttpData, input_parameters: newInputParameters});
                                        }}>
                                        <option value="">Select a value</option>
                                        <option value={true}>True</option>
                                        <option value={false}>False</option>
                                    </select>
                                </div>
                                <div className="flex mb-4">
                                    <label className="w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor={`input_parameter_${index}_autofill_on_engine`}>Autofill on Engine</label>
                                    <select className={"w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white" +
                                        (inputParameter.autofill_on_engine !== undefined ? "" : " border-red-500") }
                                        id={`input_parameter_${index}_autofill_on_engine`} placeholder="Autofill on Engine"
                                        value={inputParameter.autofill_on_engine !== undefined ? inputParameter.autofill_on_engine : ""}
                                        onChange={(e) => {
                                            const newInputParameters = [...inputParameters];
                                            newInputParameters[index].autofill_on_engine = e.target.value === "true";
                                            setTtpData({...ttpData, input_parameters: newInputParameters});
                                        }}>
                                        <option value="">Select a value</option>
                                        <option value={true}>True</option>
                                        <option value={false}>False</option>
                                    </select>
                                </div>
                                <div className="flex mb-4">
                                    <label className="w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor={`input_parameter_${index}_autofill_on_dashboard`}>Autofill on Dashboard</label>
                                    <select className={"w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white" +
                                        (inputParameter.autofill_on_dashboard !== undefined ? "" : " border-red-500") }
                                        id={`input_parameter_${index}_autofill_on_dashboard`} placeholder="Autofill on Dashboard"
                                        value={inputParameter.autofill_on_dashboard !== undefined ? inputParameter.autofill_on_dashboard : ""}
                                        onChange={(e) => {
                                            const newInputParameters = [...inputParameters];
                                            newInputParameters[index].autofill_on_dashboard = e.target.value === "true";
                                            setTtpData({...ttpData, input_parameters: newInputParameters});
                                        }}>
                                        <option value="">Select a value</option>
                                        <option value={true}>True</option>
                                        <option value={false}>False</option>
                                    </select>
                                </div>
                                <button className="bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded" onClick={() => {
                                    const newInputParameters = [...inputParameters];
                                    newInputParameters.splice(index, 1);
                                    if (newInputParameters.length === 0) {
                                        delete ttpData.input_parameters;
                                        setTtpData({...ttpData});
                                    } else {
                                        setTtpData({...ttpData, input_parameters: newInputParameters});
                                    }
                                }}>Delete Input Parameter</button>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
