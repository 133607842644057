import { inputOutputTypes } from "./enums";

export default function OutputParameters({ttpData, setTtpData, setError}) {
    const outputParameters = ttpData.output_parameters ? ttpData.output_parameters.values : null;

    return (
        <div className="w-full">
            <h1 className="block text-gray-700 text-sm mb-4 font-bold mb-2">Output Parameters</h1>
            <div className="flex flex-col space-y-4">
                <div className="flex mb-4">
                    <button className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded" onClick={() => {
                        const newOutputParameters = outputParameters ? [...outputParameters] : [];
                        newOutputParameters.push({
                            name: "",
                            display_name: "",
                            description: "",
                            type: ""
                        });
                        setTtpData({...ttpData, output_parameters: { ...ttpData.output_parameters, values: newOutputParameters}});
                    }}>Add Output Parameter</button>
                </div>
                {
                    outputParameters && outputParameters.length > 0 && (
                        <div className="flex mb-4">
                            <label className="w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor="table_hint">Table Hint</label>
                            <input className="w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="table_hint" type="text" placeholder="Table Hint"
                                value={ttpData.output_parameters.table_hint || ""}
                                onChange={(e) => {
                                    if (e.target.value === "") {
                                        const newOutputParameters = {...ttpData.output_parameters};
                                        delete newOutputParameters.table_hint;
                                        setTtpData({...ttpData, output_parameters: newOutputParameters});
                                    } else {
                                        setTtpData({...ttpData, output_parameters: {...ttpData.output_parameters, table_hint: e.target.value}})
                                    }
                                }
                                } />
                        </div>
                    )
                }
                {
                    (outputParameters || []).map((outputParameter, index) => {
                        return (
                            <div key={index} className="flex flex-col border border-gray-300 p-4 rounded">
                                <div className="flex mb-4">
                                    <label className="w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor={`output_parameters.values[${index}].name`}>Name</label>
                                    <input className={"w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" +
                                        (outputParameter.name ? "" : " border-red-500") }
                                        id={`output_parameters.values[${index}].name`} type="text" placeholder="Name"
                                        value={outputParameter.name || ""}
                                        onChange={(e) => {
                                            const newOutputParameters = [...outputParameters];
                                            newOutputParameters[index].name = e.target.value;
                                            setTtpData({...ttpData, output_parameters: {...ttpData.output_parameters, values: newOutputParameters}});
                                        }} />
                                </div>
                                <div className="flex mb-4">
                                    <label className="w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor={`output_parameters.values[${index}].display_name`}>Display Name</label>
                                    <input className={"w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" +
                                        (outputParameter.display_name ? "" : " border-red-500") }
                                        id={`output_parameters.values[${index}].display_name`} type="text" placeholder="Display Name"
                                        value={outputParameter.display_name || ""}
                                        onChange={(e) => {
                                            const newOutputParameters = [...outputParameters];
                                            newOutputParameters[index].display_name = e.target.value;
                                            setTtpData({...ttpData, output_parameters: {...ttpData.output_parameters, values: newOutputParameters}});
                                        }} />
                                </div>
                                <div className="flex mb-4">
                                    <label className="w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor={`output_parameters.values[${index}].description`}>Description</label>
                                    <textarea className={"w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" +
                                        (outputParameter.description ? "" : " border-red-500") }
                                        id={`output_parameters.values[${index}].description`} placeholder="Description"
                                        value={outputParameter.description || ""}
                                        onChange={(e) => {
                                            const newOutputParameters = [...outputParameters];
                                            newOutputParameters[index].description = e.target.value;
                                            setTtpData({...ttpData, output_parameters: {...ttpData.output_parameters, values: newOutputParameters}});
                                        }} />
                                </div>
                                <div className="flex mb-4">
                                    <label className="w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor={`output_parameters.values[${index}].type`}>Type</label>
                                    <select className={"w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white" +
                                        (outputParameter.type ? "" : " border-red-500") }
                                        id={`output_parameters.values[${index}].type`} placeholder="Type"
                                        value={outputParameter.type || ""}
                                        onChange={(e) => {
                                            const newOutputParameters = [...outputParameters];
                                            newOutputParameters[index].type = e.target.value;
                                            setTtpData({...ttpData, output_parameters: {...ttpData.output_parameters, values: newOutputParameters}});
                                        }}>
                                        <option value="">Select a type</option>
                                        {
                                            inputOutputTypes.map((type, index) => {
                                                return <option key={index} value={type}>{type}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <button className="bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded" onClick={() => {
                                    const newOutputParameters = [...outputParameters];
                                    newOutputParameters.splice(index, 1);
                                    if (newOutputParameters.length === 0) {
                                        delete ttpData.output_parameters;
                                        setTtpData({...ttpData});
                                    } else {
                                        setTtpData({...ttpData, output_parameters: {...ttpData.output_parameters, values: newOutputParameters}});
                                    }
                                }}>Delete Output Parameter</button>
                            </div>
                        )
                    })
                }
            </div>
        </div>  
    )
}
