import { levelTypes, preconditionTypes, networkProtocolTypes } from "./enums";

export default function EngineOptions({ttpData, setTtpData, setError}) {
    const scope = ttpData.engine_options ? ttpData.engine_options.scope : null;
    const preconditions = ttpData.engine_options ? ttpData.engine_options.preconditions : null;

    const addField = (newField) => {
        if (newField.trim() !== "") {
            setTtpData({
                ...ttpData,
                engine_options: {
                    ...ttpData.engine_options,
                    scope: {
                        ...scope,
                        unique_fields: [...scope.unique_fields, newField]
                    }
                }
            });
        }
    };

    const removeField = (index) => {
        setTtpData({
            ...ttpData,
            engine_options: {
                ...ttpData.engine_options,
                scope: {
                    ...scope,
                    unique_fields: scope.unique_fields.filter((field, i) => i !== index)
                }
            }
        });
    }

    return (
        <div className="w-full">
            <h1 className="block text-gray-700 text-sm mb-4 font-bold mb-2">Engine Options</h1>
            <div className="flex flex-col space-y-4">
            {
                scope ?
                <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="scope">Scope</label>
                    <div className="flex mb-4">
                        <label className="pl-10 w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor="level">Level</label>
                        <select className={"w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white" +
                            (scope.level ? "" : " border-red-500") }
                            id="level" placeholder="Level"
                            value={scope.level || ""}
                            onChange={(e) => 
                                setTtpData({
                                    ...ttpData,
                                    engine_options: {
                                        ...ttpData.engine_options,
                                        scope: {
                                            ...scope,
                                            level: e.target.value
                                        }
                                    }
                                })
                            }>
                            <option value="">Select a level</option>
                            {
                                levelTypes.map((type, index) => (
                                    <option key={index} value={type}>
                                        {type}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="flex mb-4">
                        <label className="pl-10 w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor="unique_fields">Fields</label>
                        <div className="w-2/3 flex flex-col">
                            <div className="flex mb-4">
                                <select
                                    className="grow shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white"
                                    id="unique_fields"
                                    value={""}
                                    onChange={(e) => addField(e.target.value)}
                                >
                                    <option value="">Select a field</option>
                                    {ttpData.input_parameters && ttpData.input_parameters.map((param, index) => (
                                        param &&
                                        <option key={index} value={param.name}>
                                            {param.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <ul className="w-full">
                                {scope.unique_fields.map((field, index) => (
                                    <li key={index} className="flex items-center shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2">
                                        <span className="flex-grow">{field}</span>
                                        <button
                                            className="ml-2 bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                            onClick={() => removeField(index)}
                                        >
                                            Remove
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <button className="bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded"
                        onClick={() => {
                            let engineOptions = ttpData.engine_options;
                            delete engineOptions.scope;
                            if (Object.keys(engineOptions).length === 0) {
                                let newTtpData = {...ttpData};
                                delete newTtpData.engine_options;
                                setTtpData(newTtpData);
                            } else {
                                setTtpData({
                                    ...ttpData,
                                    engine_options: engineOptions
                                })
                            }
                        }
                        }>Remove Scope</button>
                </div>
                :
                <div>
                    <button className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded"
                        onClick={() => 
                            setTtpData({
                                ...ttpData,
                                engine_options: {
                                    ...ttpData.engine_options,
                                    scope: {
                                        level: "",
                                        unique_fields: []
                                    },
                                }
                            })
                        }>Add Scope</button>
                </div>
            }

            {
                preconditions ?
                <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="preconditions">Preconditions</label>
                    <ul className="w-full">
                        <div className="flex mb-4">
                            <label className="pl-10 w-1/3 block text-gray-700 text-sm font-bold mb-2" htmlFor="type">Type</label>
                            <select className={"w-2/3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white"}
                                id="type"
                                value={""}
                                onChange={(e) => {
                                    setTtpData({
                                        ...ttpData,
                                        engine_options: {
                                            ...ttpData.engine_options,
                                            preconditions: [...preconditions, {type: e.target.value, value: {}}]
                                        }
                                    });
                                }}
                            >
                                <option value="">Select a type</option>
                                {
                                    preconditionTypes.map((type, index) => (
                                        <option key={index} value={type}>
                                            {type}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>

                        {preconditions.map((precondition, index) => (
                            <li key={index} className="flex items-center shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2">
                                <span className="flex-grow">{precondition.type}</span>
                                {
                                    precondition.type === "has_open_port" ?
                                                                       <div className="flex-grow">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="port">Port</label>
                                        <input
                                            className={"shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full" +
                                                (precondition.value.port && /^[0-9]+$/.test(precondition.value.port) ? "" : " border-red-500") }
                                            id="port"
                                            type="number"
                                            value={precondition.value.port || ""}
                                            onChange={(e) => {
                                                setTtpData({
                                                    ...ttpData,
                                                    engine_options: {
                                                        ...ttpData.engine_options,
                                                        preconditions: preconditions.map((p, i) => {
                                                            if (i === index) {
                                                                return {
                                                                    ...p,
                                                                    value: {
                                                                        ...p.value,
                                                                        port: parseInt(e.target.value)
                                                                    }
                                                                };
                                                            }
                                                            return p;
                                                        })
                                                    }
                                                });
                                            }}
                                        />
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="host">Host</label>
                                        <input
                                            className={"shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full" +
                                                (precondition.value.host ? "" : " border-red-500") }
                                            id="host"
                                            type="text"
                                            value={precondition.value.host || ""}
                                            onChange={(e) => {
                                                setTtpData({
                                                    ...ttpData,
                                                    engine_options: {
                                                        ...ttpData.engine_options,
                                                        preconditions: preconditions.map((p, i) => {
                                                            if (i === index) {
                                                                return {
                                                                    ...p,
                                                                    value: {
                                                                        ...p.value,
                                                                        host: e.target.value
                                                                    }
                                                                };
                                                            }
                                                            return p;
                                                        })
                                                    }
                                                });
                                            }}
                                        />
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="protocol">Protocol</label>
                                        <select className={"shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white w-full" +
                                            (precondition.value.protocol ? "" : " border-red-500") }
                                            id="protocol"
                                            value={precondition.value.protocol || ""}
                                            onChange={(e) => {
                                                setTtpData({
                                                    ...ttpData,
                                                    engine_options: {
                                                        ...ttpData.engine_options,
                                                        preconditions: preconditions.map((p, i) => {
                                                            if (i === index) {
                                                                return {
                                                                    ...p,
                                                                    value: {
                                                                        ...p.value,
                                                                        protocol: e.target.value
                                                                    }
                                                                };
                                                            }
                                                            return p;
                                                        })
                                                    }
                                                });
                                            }}
                                        >
                                            <option value="">Select a protocol</option>
                                            {
                                                networkProtocolTypes.map((type, index) => (
                                                    <option key={index} value={type}>
                                                        {type}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    :
                                    <></>
                                }
                                <button
                                    className="ml-2 bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                    onClick={() => {
                                        setTtpData({
                                            ...ttpData,
                                            engine_options: {
                                                ...ttpData.engine_options,
                                                preconditions: preconditions.filter((_, i) => i !== index)
                                            }
                                        });
                                    } }
                                >
                                    Remove
                                </button>
                            </li>
                        ))}
                    </ul>

                    <button className="bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded"
                        onClick={() => {
                            let engineOptions = ttpData.engine_options;
                            delete engineOptions.preconditions;
                            if (Object.keys(engineOptions).length === 0) {
                                let newTtpData = {...ttpData};
                                delete newTtpData.engine_options;
                                setTtpData(newTtpData);
                            } else {
                                setTtpData({
                                    ...ttpData,
                                    engine_options: engineOptions
                                })
                            }
                        }
                        }>Remove Preconditions</button>
                </div>
                :
                <div>
                    <button className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded"
                        onClick={() => 
                            setTtpData({
                                ...ttpData,
                                engine_options: {
                                    ...ttpData.engine_options,
                                    preconditions: []
                                }
                            })
                        }>Add Preconditions</button>
                </div>
            }
            </div>
        </div>
    )
}
