const osPlatformTypes = ["windows", "linux", "macos"];
const executableByType = ["engine", "implant", "all"];
const executorTypes = ["bash", "powershell", "command_prompt"];
const levelTypes = ["campaign", "implant"];
const preconditionTypes = ["has_open_port"];
const networkProtocolTypes = ["tcp", "udp"];
const inputOutputTypes = ["string", "user", "username", "ad_username", "regular_username", "email", "credential", "password", "plain",
         "base64", "hash", "lm", "ntlm_v1", "ntlm_v2", "md4", "md5", "sha1", "sha256", "bcrypt", "scrypt", "mac",
         "uri", "path", "local_path", "remote_path", "url", "host", "ip_address", "ipv4_address", "ipv6_address",
         "hostname", "dns_domain", "ad_domain", "ip_network", "ad_group", "integer"
];
const tableHintTypes = ["credentials", "input_capture", "scanned_host_ports", "scanned_host_details", "host_discovery_arp", "host_os_services"];

// TODO:
// recovery conditions
// resources

export { osPlatformTypes, executableByType, executorTypes, levelTypes, preconditionTypes, networkProtocolTypes, inputOutputTypes, tableHintTypes };