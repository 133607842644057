import Editor from './Editor';
import YamlPreview from './YamlPreview';
import { useMemo, useState } from 'react';

export default function App() {
    const [ttpData, setTtpData] = useState({});
    const [error, setError] = useState(null);
    const [errorTimeout, setErrorTimeout] = useState(null);

    const appendError = (newError) => {
        if (newError instanceof Error) {
            newError = newError.message;
        }
        if (error) {
            newError = error + "\n" + newError;
        }
        setError(newError);
    }

    const updateTtpData = (newData) => {
        const orderedKeys = ['atomic_test', 'name', 'description', 'severity_score', 'supported_platform', 'executable_by', 'engine_options', 'executor', 'requirements', 'input_parameters', 'output_parameters'];
        const orderedData = {};
        orderedKeys.forEach(key => {
            if (newData[key]) {
                orderedData[key] = newData[key];
            }
        });
        setTtpData(orderedData);
    }

    useMemo(() => {
        if (error) {
            if (errorTimeout) {
                clearTimeout(errorTimeout);
            }
            setErrorTimeout(setTimeout(() => {
                setError(null);
            }, 10000));
        }
    }, [error]);

    return (
        <>
        <div className='flex'>
            <div className='w-1/2 overflow-auto h-screen'>
                <Editor ttpData={ttpData} setTtpData={updateTtpData} setError={appendError} />
            </div>
            <div className='w-1/2 overflow-auto h-screen'>
                <YamlPreview ttpData={ttpData} setTtpData={updateTtpData} setError={appendError} />
            </div>
        </div>
        {   
            error && <div className='bg-red-500 absolute top-5 right-5 p-3 rounded-lg text-white cursor-pointer whitespace-pre' onClick={
                () => {
                    appendError(null);
                }
            }>{error}</div>
        }
        </>
    )
  }
  