import yaml from 'yaml';
import { useMemo, useState } from 'react';

export default function YamlPreview({ttpData, setTtpData, setError}) {
    const getTtpYaml = (ttpData) => {
        return yaml.stringify(ttpData, {indent: 4});
    }
    
    const setTtpDataFromYaml = (yamlData) => {
        try {
            setTtpData(yaml.parse(yamlData));
        } catch (e) {
            setError(e);
        }
    }

    const [yamlData, setYamlData] = useState(getTtpYaml(ttpData));

    useMemo(() => {
        setYamlData(getTtpYaml(ttpData));
    }, [ttpData]);

    return (
        <textarea className="p-10 w-full min-h-screen h-full bg-gray-200" value={yamlData} onChange={(e) => setYamlData(e.target.value)} onBlur={(e) => setTtpDataFromYaml(e.target.value)}>
        </textarea>
    )
}
